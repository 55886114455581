import styled from '@emotion/styled';
import { HelpIcon } from '@imus/base-ui';
import React, { useCallback } from 'react';
import FieldWrapper from '../../base/FieldWrapper';
import { IconButton } from '../../button';
import type { HelperTextProps } from '../../field/HelperText';
import HelperText from '../../field/HelperText';
import BaseInput from '../BaseInput';
import {
  StyledOverlineText,
  InputRow,
  LanguageIdIndicator,
  ActionsWrapper,
} from './styles';

type RenderInput = React.FC<Parameters<typeof BaseInput>[0]>;

type Props = React.ComponentPropsWithoutRef<'input'> & {
  testId?: string;
  inputRef?: React.Ref<HTMLInputElement>;
  languageId?: string;
  label?: string;
  floatingLabelText?: string; // DEPRECATED, only to support old input
  onChange?: (e: React.ChangeEvent<HTMLInputElement>, value?: string) => void;
  required?: boolean;
  onClickHelp?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  placeholder?: string;
  disabled?: boolean;
  multiline?: boolean;
  labelSize?: 'large' | 'small';
  rows?: number;
  renderInput?: RenderInput;
  helperText?: HelperTextProps['text'];
  errorText?: string | boolean; // TODO: Drop *Text part from props
  warningText?: string;
  components?: {
    Actions?: React.FC<any>;
    OverlineActions?: React.FC<any>;
  };
};
export const InputActionSeparator = styled.div`
  width: 1px;
  height: 24px;
  background: var(--fg-4, color(display-p3 0 0 0 / 0.1));
  margin: 0 16px;
`;

const Input: React.FC<Props> = ({
  testId,
  name,
  type,
  inputRef,
  languageId,
  label,
  labelSize,

  floatingLabelText,
  value,
  placeholder,
  disabled,
  onChange,
  onFocus,
  onBlur,
  required,
  multiline,
  rows,
  helperText,
  errorText,
  warningText,
  onClickHelp,
  renderInput,
  autoFocus,
  components,
  className,
  style,
  step,
}) => {
  const InputComponent = renderInput || BaseInput;

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(e, e.target.value);
      }
    },
    [onChange]
  );

  const status = errorText ? 'error' : warningText ? 'warning' : undefined;

  return (
    <FieldWrapper className={className} style={style}>
      {(label || floatingLabelText) && (
        <StyledOverlineText
          size={labelSize}
          label={label || floatingLabelText}
          error={!!errorText}
          required={!disabled && required}
          components={{ OverlineActions: components?.OverlineActions }}
        />
      )}

      <InputRow>
        <InputComponent
          step={step}
          data-test-id={testId}
          name={name}
          type={type}
          ref={inputRef}
          value={value}
          placeholder={placeholder}
          onChange={handleChange}
          onFocus={onFocus}
          onBlur={onBlur}
          autoFocus={autoFocus}
          multiline={multiline}
          rows={rows}
          status={status}
          disabled={disabled}
          style={{ paddingLeft: languageId && 40 }}
        />

        {languageId && (
          <LanguageIdIndicator className={disabled ? 'disabled' : undefined}>
            {languageId.toUpperCase()}
          </LanguageIdIndicator>
        )}

        <ActionsWrapper
          tabIndex={-1}
          className={disabled ? 'disabled' : undefined}
        >
          {components?.Actions ? <components.Actions /> : null}

          {onClickHelp && (
            <>
              {components?.Actions && <InputActionSeparator />}
              <IconButton
                inline={true}
                onClick={(e) => {
                  e.stopPropagation();
                  onClickHelp(e);
                }}
                tabIndex={-1}
              >
                <HelpIcon />
              </IconButton>
            </>
          )}
        </ActionsWrapper>
      </InputRow>

      <HelperText
        text={helperText}
        errorText={errorText}
        warningText={warningText}
      />
    </FieldWrapper>
  );
};

export default Input;
