import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { CrossIcon } from '@imus/base-ui';
import { Button } from '../button';

const cardStyles = css`
  border-radius: var(--space-S, 16px);
  border: 1px solid var(--outline-var, #c9c6c4);
  background: var(--surface-container-medium, #fffcf9);

  /* Shadow/3 */
  box-shadow:
    0px 0px 20px 0px rgba(0, 0, 0, 0.05),
    0px 4px 10px -2px rgba(0, 0, 0, 0.1);
`;

const ModalWindowTitle = styled.span`
  flex: 1;
  color: var(--on-surface, #1c1c1b);
  text-align: center;
  font-feature-settings:
    'ss01' on,
    'cv11' on;

  /* Component/Modal Window/Modal Window Title */
  padding-left: 48px;
  font-family: Barlow;
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 133.333% */
`;

const headerTitleStyles = css`
  display: flex;
  width: 100%;
  padding: 32px;
  align-items: center;
  gap: var(--space-S, 16px);
  align-self: stretch;
`;
const contentStyles = css`
  padding: 0px 32px 32px 32px;
`;

export const WindowContent = ({
  title,
  onRequestClose,
  children,
  style,
  className,
}: {
  style?: React.CSSProperties;
  className?: string;
  children: React.ReactNode;
  title: string;
  onRequestClose: () => void;
}) => {
  return (
    <div css={cardStyles} style={style} className={className}>
      <div css={headerTitleStyles}>
        <ModalWindowTitle>{title}</ModalWindowTitle>
        <Button icon={CrossIcon} onClick={onRequestClose} />
      </div>
      <div css={contentStyles}>{children}</div>
    </div>
  );
};
