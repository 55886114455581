import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import type { ButtonProps } from '../Button';
import Button from '../Button';

type LinkProps = {
  to: string;
  history?: any;
  location?: any;
  external?: boolean;
  match?: any;
  state?: any;
  staticContext?: any;
};

type LinkButtonProps = ButtonProps & LinkProps;

const LinkButton: React.FC<LinkButtonProps> = (props) => {
  const { onClick, external, to, state } = props;
  const history = useHistory();

  const handleClick = useCallback(
    (e) => {
      if (!external) {
        if (onClick) {
          onClick(e);
        }
        history.push(to, state);
      } else {
        window.open(to, '_blank');
      }
    },
    [to, onClick, external, state]
  );

  return <Button {...props} onClick={handleClick} />;
};

export default LinkButton;
