import styled from '@emotion/styled';

export const Card = styled.div`
  background: var(--bg-2);
  box-shadow: 0px 2px 6px -2px rgba(0, 0, 0, 0.05);
  border: 1px solid var(--fg-4);
  border-radius: 20px;
`;

export const SurfaceCard = styled.div`
  display: flex;
  padding: 16px;
  gap: 16px;

  border-radius: 28px;
  background: var(--surface, rgba(255, 255, 255, 0.5));
`;
