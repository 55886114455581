import styled from '@emotion/styled';
import React from 'react';

type Props = {
  d?: string;
  size?: number;
  className?: string;
  style?: React.CSSProperties;
};

const IconSvg = styled.svg`
  fill: #333;
`;

const Icon: React.FC<Props> = ({ d, size = 24, className, style }) => (
  <IconSvg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    {...{ xmlnsXlink: 'http://www.w3.org/1999/xlink' }}
    viewBox="0 0 24 24"
    className={className}
    style={style}
    width={size}
    height={size}
  >
    <path d={d} />
  </IconSvg>
);

export default Icon;
