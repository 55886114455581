import { css } from '@emotion/react';

const baseColors = css`
  /* OLD */
  --black-95: rgba(0, 0, 0, 0.95);
  --black-65: rgba(0, 0, 0, 0.65);
  --black-35: rgba(0, 0, 0, 0.35);
  --black-10: rgba(0, 0, 0, 0.1);

  --white-95: rgba(255, 255, 255, 0.95);
  --white-65: rgba(255, 255, 255, 0.65);
  --white-35: rgba(255, 255, 255, 0.35);
  --white-10: rgba(255, 255, 255, 0.1);

  --gray-100: #ffffff;
  --gray-95: #fafafa;
  --gray-90: #f4f4f4;
  --gray-85: #eeeeee;
  --gray-33: #333333;
  --gray-20: #272727;
  --gray-15: #212121;
  --gray-10: #1a1a1a;

  /* Vibrant */
  /* color */
  --blue-green-10: #001f29;
  --blue-green-10-8: #001f2914;
  --blue-green-20: #003545;
  --blue-green-30: #004d63;
  --blue-green-30-8: #004d6314;
  --blue-green-40: #27657c;
  --blue-green-50: #447e96;
  --blue-green-60: #5f98b1;
  --blue-green-70: #79b3cc;
  --blue-green-80: #95cfe9;
  --blue-green-80-8: #95cfe914;
  --blue-green-90: #bce9ff;
  --blue-green-99: #fafcff;
  --blue-green-99-8: #fafcff14;
  --bonbon-10: #3e0020;
  --bonbon-10-8: #3e00201a;
  --bonbon-20: #640037;
  --bonbon-30: #8d004f;
  --bonbon-40: #b80069;
  --bonbon-50: #dc2a83;
  --bonbon-60: #fe489d;
  --bonbon-60-8: #fe489d14;
  --bonbon-70: #ff83b3;
  --bonbon-80: #ffb0cb;
  --bonbon-80-8: #ffb0cb14;
  --bonbon-90: #ffd9e3;
  --bonbon-99: #fffbff;
  --bonbon-99-8: #fffbff14;
  --brand-alabaster: #e8eddf;
  --brand-black: #0a0908;
  --brand-midnight-green: #004e64;
  --brand-rose-bonbon: #ff499e;
  --brand-screamin-green: #73fc8a;
  --brand-sunglow: #fdca40;
  --gray-10: #1c1c1b;
  --gray-10-12: #1c1c1b1f;
  --gray-10-8: #1c1c1b14;
  --gray-10-80: #1c1c1bcc;
  --gray-100: #ffffff;
  --gray-14: #242423;
  --gray-18: #2c2c2b;
  --gray-22: #353534;
  --gray-26: #3e3d3c;
  --gray-30: #474745;
  --gray-40: #5f5e5d;
  --gray-50: #787775;
  --gray-60: #92908f;
  --gray-70: #adaba9;
  --gray-80: #c9c6c4;
  --gray-90: #e5e2e0;
  --gray-90-80: #e5e2e0cc;
  --gray-93: #edeae8;
  --gray-96: #f6f3f1;
  --gray-96-12: #f6f3f11f;
  --gray-96-8: #f6f3f114;
  --gray-99: #fffcf9;
  --green-10: #002107;
  --green-10-8: #00210714;
  --green-20: #003912;
  --green-30: #00531d;
  --green-40: #006e29;
  --green-50: #008a36;
  --green-60: #00a743;
  --green-60-8: #00a74314;
  --green-70: #34c45a;
  --green-80: #56e072;
  --green-90: #75fe8b;
  --green-90-8: #75fe8b14;
  --green-99: #fefffe;
  --green-99-8: #fefffe14;
  --orange-10: #341100;
  --orange-50: #c75400;
  --orange-60: #f06700;
  --orange-99: #fffbff;
  --red-orange-10: #410002;
  --red-orange-50: #e32e2a;
  --red-orange-60: #ff5449;
  --red-orange-99: #fffbff;
  --sunglow-10: #251a00;
  --sunglow-10-8: #251a001a;
  --sunglow-20: #3e2e00;
  --sunglow-30: #5a4400;
  --sunglow-40: #775a00;
  --sunglow-50: #957200;
  --sunglow-60: #b48b00;
  --sunglow-60-8: #b48b0014;
  --sunglow-70: #d5a400;
  --sunglow-80: #f6be00;
  --sunglow-80-8: #f6be0014;
  --sunglow-90: #ffdf97;
  --sunglow-99: #fffbff;
  --sunglow-99-8: #fffbff14;
`;

export const DARK_MODE_COLORS = css`
  --background: var(--gray-10);
  --inverted-on-surface: var(--gray-18);
  --inverted-surface-container: var(--gray-96);
  --on-surface: var(--gray-96);
  --on-surface-var: var(--gray-80);
  --on-surface-var-2: var(--gray-50);
  --outline: var(--gray-40);
  --outline-var: var(--gray-30);
  --semi-background: var(--gray-10-80);
  --state-active-on-surface: var(--gray-96-12);
  --state-hover-on-surface: var(--gray-96-8);
  --surface-container-high: var(--gray-26);
  --surface-container-low: var(--gray-18);
  --surface-container-lowest: var(--gray-14);
  --surface-container-medium: var(--gray-22);

  --primary-accent: var(--green-90);
  --primary-accent-surface-container: var(--green-10);
  --primary-on-accent: var(--green-10);
  --primary-on-accent-surface-container: var(--green-99);
  --primary-on-accent-surface-container-var: var(--green-80);
  --primary-on-accent-surface-container-var-2: var(--green-70);
  --primary-state-hover-accent: var(--green-90-8);
  --primary-state-hover-on-accent: var(--green-10-8);
  --primary-state-hover-on-accent-surface: var(--green-99-8);

  --tertiary-accent: var(--sunglow-80);
  --tertiary-accent-surface-container: var(--sunglow-10);
  --tertiary-on-accent: var(--sunglow-10);
  --tertiary-on-accent-surface-container: var(--sunglow-99);
  --tertiary-on-accent-surface-container-var: var(--sunglow-80);
  --tertiary-on-accent-surface-container-var-2: var(--sunglow-70);
  --tertiary-state-hover-accent: var(--sunglow-80-8);
  --tertiary-state-hover-on-accent: var(--sunglow-10-8);
  --tertiary-state-hover-on-accent-surface: var(--sunglow-99-8);

  --error-error: var(--red-orange-60);
  --error-error-surface-container: var(--red-orange-10);
  --error-on-error-surface: var(--red-orange-99);

  --fg-1: var(--on-surface);
  --fg-2: var(--on-surface-var);
  --fg-3: var(--on-surface-var-2);
  --fg-4: var(--outline-var);

  --bg-4: var(--background);
  --bg-3: var(--surface-container-low);
  --bg-2: var(--surface-container-medium);
  --bg-1: var(--surface-container-high);
  --surface: rgba(255, 255, 255, 0.1);
`;

export const LIGHT_MODE_COLORS = `
  --background: var(--gray-90);
  --inverted-on-surface: var(--gray-96);
  --inverted-surface-container: var(--gray-10);
  --on-surface: var(--gray-10);
  --on-surface-var: var(--gray-22);
  --on-surface-var-2: var(--gray-40);
  --outline: var(--gray-70);
  --outline-var: var(--gray-80);
  --semi-background: var(--gray-90-80);
  --state-active-on-surface: var(--gray-10-12);
  --state-hover-on-surface: var(--gray-10-8);
  --surface-container-high: var(--gray-100);
  --surface-container-low: var(--gray-96);
  --surface-container-lowest: var(--gray-93);
  --surface-container-medium: var(--gray-99);

  --primary-accent: var(--blue-green-30);
  --primary-accent-surface-container: var(--blue-green-99);
  --primary-on-accent: var(--blue-green-99);
  --primary-on-accent-surface: var(--blue-green-10);
  --primary-on-accent-surface-var: var(--blue-green-20);
  --primary-on-accent-surface-var-2: var(--blue-green-30);
  --primary-state-hover-accent: var(--blue-green-30-8);
  --primary-state-hover-on-accent: var(--blue-green-99-8);
  --primary-state-hover-on-accent-surface: var(--blue-green-10-8);


  --tertiary-accent: var(--bonbon-60);
  --tertiary-accent-surface-container: var(--bonbon-99);
  --tertiary-on-accent: var(--bonbon-99);
  --tertiary-on-accent-surface: var(--bonbon-10);
  --tertiary-on-accent-surface-var: var(--bonbon-20);
  --tertiary-on-accent-surface-var-2: var(--bonbon-30);
  --tertiary-state-hover-accent: var(--bonbon-60-8);
  --tertiary-state-hover-on-accent: var(--bonbon-99-8);
  --tertiary-state-hover-on-accent-surface: var(--bonbon-10-8);

  --fg-1: var(--on-surface);
  --fg-2: var(--on-surface-var);
  --fg-3: var(--on-surface-var-2);
  --fg-4: var(--outline-var);

  --bg-4: var(--background);
  --bg-3: var(--surface-container-low);
  --bg-2: var(--surface-container-medium);
  --bg-1: var(--surface-container-high);

  --error-error: var(--red-orange-50);
  --error-error-surface-container: var(--red-orange-99);
  --error-on-error-surface: var(--red-orange-10);
  --surface: rgba(255, 255, 255, 0.50);

`;

export const errorThemes = {
  error: css`
    --error: var(--error-error);
    --error-surface-container: var(--error-error-surface-container);
    --on-error-surface: var(--error-on-error-surface);
  `,
};

export const accentThemes = {
  primary: css`
    --fixed-accent: var(--primary-fixed-accent);
    --accent: var(--primary-accent);
    --accent-surface-container: var(--primary-accent-surface-container);
    --on-accent: var(--primary-on-accent);
    --on-accent-surface: var(--primary-on-accent-surface);
    --on-accent-surface-var: var(--primary-on-accent-surface-var);
    --on-accent-surface-var-2: var(--primary-on-accent-surface-var-2);
    --state-hover-accent: var(--primary-state-hover-accent);
    --state-hover-on-accent: var(--primary-state-hover-on-accent);
    --state-hover-on-accent-surface: var(
      --primary-state-hover-on-accent-surface
    );
  `,
  secondary: css`
    --fixed-accent: var(--primary-fixed-accent);
    --accent: var(--primary-accent);
    --accent-surface-container: var(--primary-accent-surface-container);
    --on-accent: var(--primary-on-accent);
    --on-accent-surface: var(--primary-on-accent-surface);
    --on-accent-surface-var: var(--primary-on-accent-surface-var);
    --on-accent-surface-var-2: var(--primary-on-accent-surface-var-2);
    --state-hover-accent: var(--primary-state-hover-accent);
    --state-hover-on-accent: var(--primary-state-hover-on-accent);
    --state-hover-on-accent-surface: var(
      --primary-state-hover-on-accent-surface
    );
  `,
  tertiary: css`
    --fixed-accent: var(--tertiary-fixed-accent);
    --accent: var(--tertiary-accent);
    --accent-surface-container: var(--tertiary-accent-surface-container);
    --on-accent: var(--tertiary-on-accent);
    --on-accent-surface: var(--tertiary-on-accent-surface);
    --on-accent-surface-var: var(--tertiary-on-accent-surface-var);
    --on-accent-surface-var-2: var(--tertiary-on-accent-surface-var-2);
    --state-hover-accent: var(--tertiary-state-hover-accent);
    --state-hover-on-accent: var(--tertiary-state-hover-on-accent);
    --state-hover-on-accent-surface: var(
      --tertiary-state-hover-on-accent-surface
    );
  `,
};
export const secondaryTheme = accentThemes.secondary;
export const primaryTheme = accentThemes.primary;
export const tertiaryTheme = accentThemes.tertiary;

export const darkTheme = DARK_MODE_COLORS;

export const baseTheme = css`
  ${baseColors}
  ${errorThemes.error}
  ${LIGHT_MODE_COLORS}

  ${primaryTheme}

  --artist-hub: #b151c9;
  --music-distribution: #16ad40;
  --instant-mastering: #ffa800;

  --sub-amplify: #3cbeb1;
  --sub-amplify-plus: #926ef7;
  --sub-amplify-pro: #e4497d;

  --accent-green: #82bd32;
  --accent-orange: #fb9013;

  --state-positive: #00c82c;
  --state-negative: var(--error);

  --fg-inverted: var(--white-95);

  --bg-inverted: var(--gray-10);
`;
