import { css } from '@emotion/react';
import styled from '@emotion/styled';
import FocusTrap from 'focus-trap-react';
import { AnimatePresence } from 'framer-motion';
import React, { useRef, useCallback, useEffect } from 'react';
import { Portal } from 'react-portal';
import { AnimatedDim, WindowCard, WindowChildren } from './styles';

export { AnimatedDim } from './styles';

type Props = {
  node?: HTMLElement | null | undefined;
  hideClose?: boolean;
  children: React.ReactNode;
  controls?: React.ReactNode;
  isOpen?: boolean;
  title?: React.ReactNode;
  hideTitleBar?: boolean;
  close?: (
    e?: React.MouseEvent<HTMLElement>,
    type?: 'x-button' | 'outside' | 'event'
  ) => void;
  closeOnLocation?: boolean;
  onClose?: () => void;
  className?: string;
  style?: any;
  lockClickOutside?: boolean;
};

const dimBefore = { opacity: 0 };
const dimAfter = { opacity: 1 };

const windowBefore = { scale: 0.9 };
const windowInital = { scale: 1 };
const windowAfter = { scale: 1 };

const animConfig = {
  type: 'spring',
  stiffness: 700,
  damping: 30,
};

const windowContentStyle = css`
  width: 100%;
  height: 100%;
`;
const stopBubbleClickOutside: React.MouseEventHandler<HTMLDivElement> = (e) => {
  e.stopPropagation();
};

// TODO: Merge with imdui/Window.

const CloseIcon = ({
  className,
  style,
}: {
  className?: string;
  style?: React.CSSProperties;
}) => (
  <svg
    className={className}
    style={style}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.7069 7.29289C17.0974 7.68342 17.0974 8.31658 16.7069 8.70711L13.414 12L16.7071 15.2929C17.0976 15.6834 17.0976 16.3165 16.7071 16.7071C16.3166 17.0976 15.6835 17.0976 15.2929 16.7071L11.9997 13.4142L8.70711 16.7069C8.31658 17.0974 7.68342 17.0974 7.29289 16.7069C6.90237 16.3163 6.90237 15.6832 7.29289 15.2926L10.5855 12L7.29299 8.70752C6.90246 8.317 6.90246 7.68383 7.29299 7.29331C7.68351 6.90279 8.31667 6.90279 8.7072 7.29331L11.9997 10.5858L15.2926 7.29289C15.6832 6.90237 16.3163 6.90237 16.7069 7.29289Z"
      fill="white"
      fillOpacity="0.95"
    />
  </svg>
);

const CloseButtonBase = (
  props: Omit<
    React.DetailedHTMLProps<
      React.ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >,
    'children'
  >
) => {
  return (
    <button {...props}>
      <CloseIcon />
    </button>
  );
};

export const WindowCloseButton = styled(CloseButtonBase)`
  position: absolute;
  right: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 24px;
  background: var(--fg-4, rgba(0, 0, 0, 0.1));
  path {
    fill: var(--fg-1);
  }
  border-radius: 16px;
  width: 32px;
  height: 32px;
  border: none;
  cursor: pointer;
  z-index: 204;
  &:hover {
    opacity: 0.75;
  }
`;

const OverlayCloseButton = styled(WindowCloseButton)`
  background: rgba(255, 255, 255, 0.1);
  path {
    fill: white;
  }
`;

export const Window: React.FC<Props> = ({
  node,
  children,
  isOpen = false,
  lockClickOutside = false,
  controls,
  close,
  onClose,
  hideClose = false,
  className,
  style,
}) => {
  const containerNode = useRef<HTMLDivElement>(null);
  const focusNode = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!isOpen && onClose) {
      onClose();
    }
  }, [isOpen]);
  const onClickInside = useCallback((e) => {
    e.stopPropagation();
  }, []);

  const onClickOutside = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      if (
        !lockClickOutside &&
        !focusNode?.current?.contains(e.target as Node)
      ) {
        e.stopPropagation();
        if (close) {
          close(e as React.MouseEvent<HTMLElement>, 'outside');
        }
      }
    },
    [lockClickOutside, close]
  );
  const handleKeyDown = useCallback((e) => {
    e.stopPropagation();
  }, []);

  if (typeof window === 'undefined') return null;

  return (
    <Portal
      node={
        node ||
        document.getElementById('app') ||
        document.getElementById('root')
      }
    >
      <AnimatePresence>
        {isOpen && (
          <FocusTrap
            focusTrapOptions={{
              allowOutsideClick: true,
              fallbackFocus: 'body',
            }}
          >
            <div
              onKeyDown={handleKeyDown}
              data-test-id="window"
              ref={containerNode}
            >
              {!hideClose && (
                <OverlayCloseButton
                  type="button"
                  onClick={(e) => {
                    if (close) {
                      close(e as React.MouseEvent<HTMLElement>, 'outside');
                    }
                  }}
                />
              )}
              {controls}
              <AnimatedDim
                tabIndex={0}
                initial={dimBefore}
                animate={dimAfter}
                exit={dimBefore}
                transition={animConfig}
                onClick={stopBubbleClickOutside}
                onMouseDown={onClickOutside}
              >
                <WindowCard
                  initial={windowInital}
                  animate={windowAfter}
                  exit={windowBefore}
                  transition={animConfig}
                  className={className}
                  style={style}
                  ref={focusNode}
                >
                  <div onClick={onClickInside} css={windowContentStyle}>
                    <WindowChildren>{children}</WindowChildren>
                  </div>
                </WindowCard>
              </AnimatedDim>
            </div>
          </FocusTrap>
        )}
      </AnimatePresence>
    </Portal>
  );
};

export const PageLayer = styled(Window)`
  max-width: 768px;
  margin: 16px 16px 0 16px;
  max-height: initial;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  height: calc(100% - 16px);
  background: transparent;
`;
