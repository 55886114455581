import { css } from '@emotion/react';

export const scrollWrapperStyle = css`
  display: flex;
  align-items: center;
  flex: 1;

  a {
    text-decoration: none;
  }

  flex-direction: column;
`;

export const avatarSection = css`
  display: flex;
  flex: 1;
  width: 100%;
  align-items: center;
  gap: var(--space-M, 32px);
  text-align: left;
`;

export const pageWrapper = css`
  flex: 1;
  max-width: 632px;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 32px 16px 0 16px;
  padding: 0 16px;

  gap: 32px;
`;

export const artistTitle = css`
  display: flex;
  align-items: center;
  color: var(--on-surface, #1b1b1b);
  font-feature-settings:
    'ss01' on,
    'cv11' on;
  font-family: Barlow;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 107.692% */
  margin-bottom: 8px;
  justify-content: space-between;
  svg {
    width: 24px;
    height: 24px;
  }
`;
export const avatarStyle = css`
  width: 128px;
  flex-shrink: 0;
  object-fit: cover;
  display: block;
  height: 128px;
  overflow: hidden;
  border-radius: 50%;
  display: flex;
  background-color: var(--surface);
`;

export const navSectionStyle = css`
  display: flex;
  flex-direction: column;
  container-type: inline-size;
  width: 100%;
  align-items: center;
  text-align: center;
  gap: 32px;
  margin: 0 auto;
  justify-self: center;
`;

export const bioStyle = css`
  display: flex;
  flex: 1;
  flex-direction: column;
  // justify-content: space-between;
  height: 100%;
  & > * {
    color: var(--on-surface, #1b1b1b);
  }
`;
export const coverTextStyle = css`
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;

  @container artist-template-sizer (width > 1024px) {
    gap: 16px;
  }
`;

export const socialDesktop = css`
  display: flex;
  z-index: 1;
  margin-top: auto;
  width: 100%;
  @container artist-template-sizer (width < 1024px) {
    display: none;
  }
`;
export const contentFooter = css`
  display: flex;
  flex-direction: column;
  z-index: 1;
  margin: auto 0 0;
  padding: 32px 0;
  width: 100%;
  justify-content: space-between;
  align-items: baseline;
  flex-wrap: wrap;
  gap: 16px;
  border-top: 1px solid var(--outline, rgba(27, 27, 27, 0.2));
  a {
    color: var(--fg-1);
  }
`;

export const artistPageProductLine = css`
  width: 100%;
  display: flex;
  gap: 16px;
  justify-content: space-between;
  align-items: center;
  svg path {
    fill: var(--on-surface) !important;
  }
`;

export const contentSectionStyle = css`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const childrenWrapperStyle = css`
  // use flex: 1 for [mobile] and [desktop] to always keep the branding at the
  // bottom, no matter the content length
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  gap: 32px;
`;
