/**
 * All accent colors
 */

const accent = {
  green: '#8ac54c',
  orange: '#f58220',
  subscription: '#FB9013',
};

/**
 * State colors
 */
const state = {
  warning: '#f9a825',
  error: '#e93535',
};

/**
 * Monochromatic palette of black shades for light background
 */

/**
 * Theme with light background (Default)
 */
export const theme = {
  foreground: {
    primary: 'rgba(0, 0, 0, 0.8)',
    secondary: '#555',
    disabled: '#666666',
  },
  accent,
  state,
  darks: {
    1: '#333333',
    2: '#444444',
    3: '#555555',
    4: '#666666',
  },
  lights: {
    1: '#f4f4f4',
    2: '#e1e1e1',

    3: '#d3d3d3',
    4: '#999999',
  },
  background: '#F2F2F3',
  component: {
    radius: '8px',
    border: '#ececec',
    card: '#FFFFFF',
    button: {
      background: '#E5E5E6',
      focusBackground: '#0000004d',
    },
    input: {
      background: '#FAFAFA',
      border: '#DDDDDD',
      focusBorder: '#CCCCCC',
    },
  },
};

export const themeLight = theme;
export default theme;
