import { keyframes } from '@emotion/react';

export const appear = keyframes`
  0% {
    background-color: rgba(0, 0, 0, 0);
    transform: scale(0);
  }
  100% {
    background-color: rgba(0, 0, 0, 0.2);
    transform: scale(1);
  }
`;

export const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.75);
  }
  100% {
    transform: scale(1);
  }
`;
