import React from 'react';
import SVGGroup from '../SVGGroup';

interface Props {
  d?: string;
  viewBox?: string;
  title?: string;
  color?: React.CSSProperties['color'];
  size?: string | number;
  className?: string;
  style?: any;
}

const SVGIcon: React.FC<Props> = ({
  d = '',
  title,
  color = '333',
  size = 20,
  className,
  style,
  viewBox,
}) => (
  <SVGGroup
    viewBox={viewBox}
    group={[{ d, color: color || '' }]}
    size={{ width: size, height: size }}
    title={title}
    className={className}
    style={style}
  />
);

export default SVGIcon;
