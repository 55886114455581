import { css } from '@emotion/react';

const placeholderStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const imgStyle = css`
  object-fit: cover;
  width: 100%;
  height: 100%;
  display: block;
`;
export const ArtistAvatarPlaceholder = ({
  style,
  className,
}: {
  style?: React.CSSProperties;
  className?: string;
}) => (
  <span css={placeholderStyle} style={style} className={className}>
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.9999 17C26.2091 17 27.9999 15.2091 27.9999 13C27.9999 10.7909 26.2091 9 23.9999 9C21.7908 9 19.9999 10.7909 19.9999 13C19.9999 15.2091 21.7908 17 23.9999 17ZM19.9343 24.2938C21.1922 24.1008 22.5461 24 23.9999 24C25.4538 24 26.8077 24.1008 28.0656 24.2938C35.2858 25.4012 39.3479 29.5446 41.0455 35.0903C42.177 38.787 38.8659 42 34.9999 42H12.9999C9.13395 42 5.82287 38.787 6.95444 35.0903C8.65199 29.5446 12.7141 25.4012 19.9343 24.2938ZM20.4838 20.1879C17.8283 18.8864 15.9999 16.1567 15.9999 13C15.9999 8.58172 19.5817 5 23.9999 5C28.4182 5 31.9999 8.58172 31.9999 13C31.9999 16.1567 30.1716 18.8864 27.5161 20.1879C26.4548 20.708 25.2615 21 23.9999 21C22.7384 21 21.5451 20.708 20.4838 20.1879ZM37.2485 36.7308C37.2752 36.6276 37.289 36.4843 37.2206 36.2611C36.4421 33.7178 35.0994 31.7458 33.15 30.376C31.1831 28.994 28.2763 28 23.9999 28C19.7236 28 16.8167 28.994 14.8499 30.376C12.9005 31.7458 11.5578 33.7178 10.7793 36.2611C10.7109 36.4843 10.7247 36.6276 10.7513 36.7308C10.7821 36.8503 10.8602 37.0212 11.0405 37.2163C11.4258 37.6331 12.1418 38 12.9999 38H34.9999C35.8581 38 36.5741 37.6331 36.9594 37.2163C37.1397 37.0212 37.2177 36.8503 37.2485 36.7308Z"
        fill="black"
        fillOpacity="0.9"
      />
    </svg>
  </span>
);

export const ArtistAvatar = ({
  src,
  ...props
}: {
  src?: string;
  style?: React.CSSProperties;
  className?: string;
}) => {
  if (!src) return <ArtistAvatarPlaceholder {...props} />;
  return <img src={src} css={imgStyle} {...props} />;
};
