import type { SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import type React from 'react';

type TextProps = {
  secondary?: boolean;
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  loading?: boolean;
  empty?: boolean;
};

const bodyTextColor = (): SerializedStyles => css`
  color: var(--fg-1);
`;

const textColor = (): SerializedStyles => css`
  color: var(--fg-1);
`;

const placeholderStyle = ({
  loading,
  empty,
}: TextProps): SerializedStyles | null => {
  if (loading) {
    return css`
      :after {
        content: '';
        top: 2.5px;
        left: 0;
        width: 100%;
        height: calc(100% - 5px);
        border-radius: 2px;
        background-color: rgba(0, 0, 0, 0.05);
        position: absolute;
      }
    `;
  }

  if (empty) {
    return css`
      :after {
        content: '–';
        position: relative;
        color: rgba(0, 0, 0, 0.25);
      }
    `;
  }
  return null;
};

export const Caption = styled.span<TextProps>`
  font-family: 'Inter', Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.15px;
  font-feature-settings: 'cv11' on;
  ${bodyTextColor};
  ${placeholderStyle};
`;

export const Body = styled.span<TextProps>`
  font-family: 'Inter', Roboto;
  font-style: normal;
  font-size: 15px;
  line-height: 20px;

  font-feature-settings: 'cv11' on;

  color: var(--fg-${({ secondary }) => (secondary ? '2' : '1')});
  ${placeholderStyle};
`;

export const Content = styled.span<TextProps>`
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  font-family: Barlow, 'Inter', Roboto;

  font-feature-settings: 'cv11' on;

  color: var(--fg-${({ secondary }) => (secondary ? '2' : '1')});
  ${placeholderStyle};
`;

export const BodyXS = styled.span`
  font-family: 'Inter', sans-serif;
  color: var(--fg-2);
  font-weight: 400;
  font-size: 11px;
  line-height: 12px;
  letter-spacing: 0.2px;
`;
export const BodySmall = styled.span<TextProps>`
  font-family: 'Inter', sans-serif;
  position: relative;
  font-size: 13px;
  line-height: 16px;
  color: var(--fg-${({ secondary }) => (secondary ? '2' : '1')});
  ${placeholderStyle};
`;

export const Action = styled.span<TextProps>`
  font-family: Barlow;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 133.333% */
  letter-spacing: 0.3px;../ButtonText
  ${textColor}
  ${placeholderStyle};
`;

export const ButtonText = styled(Action) <{
  size?: 'large' | 'medium' | 'small';
  leftIcon?: boolean;
  showLoading?: boolean;
  appearance?: 'stroke' | 'fill';
}>`
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  user-select: none;
  text-align: left;
  transition: all 0.1s;
  font-size: ${({ size = 'medium' }) =>
    size === 'large' ? '17px' : size === 'medium' ? '15px' : '13px'};
  color: ${({ appearance = 'stroke' }) =>
    appearance === 'stroke'
      ? 'var(--on-surface)'
      : 'var(--on-accent, #FEFFFE)'};

  opacity: ${({ showLoading }) => (showLoading ? '0 !important' : 1)};
`;
export const ContentSmall = styled.span<TextProps>`
  font-family: 'Barlow', Roboto;
  position: relative;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.325px;
  font-weight: 500;
  ${textColor};
  ${placeholderStyle};
`;

export const H3 = styled.h3<TextProps>`
  font-family: 'Inter', Roboto;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 133% */

  letter-spacing: -0.15px;
  font-feature-settings: 'cv11' on;
  ${textColor};
  ${placeholderStyle};
`;

export const H4 = styled.h4`
  font-family: 'Barlow', Roboto;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;

  font-feature-settings: 'cv11' on;

  color: rgba(0, 0, 0, 0.8);
  ${textColor};
  ${placeholderStyle};
`;

export const H2 = styled.h2<TextProps>`
  font-family: 'Barlow', Roboto;
  position: relative;
  font-size: 24px;
  line-height: 28px;
  font-weight: 500;
  line-height: 24px;

  letter-spacing: -0.01em;
  font-feature-settings: 'cv11' on;
  ${textColor};
  ${placeholderStyle};
`;

export const H1 = styled.h1<TextProps>`
  font-family: 'Barlow', 'Roboto', 'sans-serif';
  position: relative;
  font-size: 28px;
  line-height: 24px;
  font-weight: 700;
  ${textColor};
  ${placeholderStyle};
`;
/** */
export const BodyL = styled.span`
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: var(--fg-1);
`;
/** */
export const BodyM = Body;
/** */
export const BodyS = BodySmall;

export const ContentS = ContentSmall;
export const ContentM = Content;

export const ContentL = styled.span`
  font-family: 'Inter', sans-serif;
  color: var(--fg-1);
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
`;
