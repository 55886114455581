import { keyframes, css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useCallback, forwardRef } from 'react';
import { ButtonText } from '@imus/base-ui';

type ClickableProps = {
  children: React.ReactNode;
  type?: string;
  testId?: string;
  autoFocus?: boolean;
  // focused?: boolean;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  onFocus?: React.FocusEventHandler<HTMLButtonElement>;
  onBlur?: React.FocusEventHandler<HTMLButtonElement>;
  onContextMenu?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  className?: string;
  style?: React.CSSProperties;
  title?: string;
};

const focus = keyframes`
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.2;
    }
    100% {
      opacity: 0;
    }
`;

const isClickable = (props: { onClick?: any; disabled?: any; type?: any }) =>
  props.onClick || props.type === 'submit';

export const clickableStyle = (props: {
  onClick?: any;
  disabled?: any;
  type?: any;
}) => css`
  position: relative;
  cursor: ${isClickable(props) ? 'pointer' : 'default'};

  &:after {
    pointer-events: ${isClickable(props) ? 'all' : 'none'};
    top: 0;
    left: 0;
    content: '';
    box-sizing: border-box;
    position: absolute;
    transition: background-color 0.1s;
    border-radius: inherit;
    background-color: transparent;
    height: 100%;
    width: 100%;
  }
  ${ButtonText} {
    opacity: ${props.disabled ? '0.3' : '1'};
  }

  :hover::after {
    background-color: ${isClickable(props)
    ? 'var(--state-hover-on-surface, rgba(28, 28, 27, 0.08))'
    : 'transparent'};
  }

  &:focus::after {
    animation: ${focus} 1.25s forwards infinite;
  }
`;

const TouchTargetContainer = styled.span`
  display: inline-flex;
  width: fit-content;
  height: fit-content;
  position: relative;
`;

const TouchTargetArea = styled.span`
  cursor: pointer;
  display: inline-flex;
  border-radius: 20px;
  box-sizing: content-box;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 8px;
  z-index: 1;

  :hover {
    background-color: var(--state-hover-on-surface, rgba(28, 28, 27, 0.08));
  }
`;

export const TouchTarget = ({
  onClick,
  style,
  className,
  children,
}: {
  onClick: React.MouseEventHandler;
  children: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
}) => {
  return (
    <TouchTargetContainer style={style} className={className}>
      <TouchTargetArea onClick={onClick}></TouchTargetArea>
      {children}
    </TouchTargetContainer>
  );
};

export const ClickableButton = styled.button`
  position: relative;
  display: flex;
  border-radius: inherit;
  &:after {
    border-radius: 12px;
  }
  ${(props) => clickableStyle(props)}
`;
const Clickable = forwardRef((props: ClickableProps, ref) => {
  const {
    children,
    type = 'button',
    testId,
    autoFocus,
    // focused,
    disabled,
    onFocus,
    onBlur,
    onClick,
    className,
    onContextMenu,
    style,
    title,
  } = props;
  const canClick = onClick && !disabled;
  const handleClick = useCallback(
    (e) => {
      if (canClick) {
        onClick(e);
      }
    },
    [onClick, disabled]
  );

  const handleOnMouseDown = useCallback((e) => {
    e.preventDefault();
  }, []);

  return (
    <ClickableButton
      title={title}
      // @ts-ignore
      ref={ref}
      // @ts-ignore
      // eslint-disable-next-line react/button-has-type
      type={type}
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus={autoFocus}
      tabIndex={disabled ? -1 : 0}
      disabled={disabled}
      className={className}
      onContextMenu={onContextMenu}
      onClick={canClick ? handleClick : undefined}
      onBlur={onBlur}
      onFocus={onFocus}
      onMouseDown={handleOnMouseDown}
      style={style}
      data-test-id={testId}
    >
      {children}
    </ClickableButton>
  );
});

Clickable.displayName = 'Clickable';

export default Clickable;
