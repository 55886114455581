import { css } from '@emotion/react';

export const primitives = css`
  --gray-10: #1b1b1b;
  --gray-10-20: #1b1b1b33;
  --gray-100: #ffffff;
  --gray-100-10: #ffffff1a;
  --gray-100-50: #ffffff80;
  --gray-15: #262626;
  --gray-91: #e5e5e5;
  --vibrant-0-15-15: #351f26;
  --vibrant-0-3-91: #ede3e4;
  --vibrant-0-35-30: #6f3348;
  --vibrant-0-50-70: #f789ae;
  --vibrant-120-15-15: #242812;
  --vibrant-120-3-91: #e8e5df;
  --vibrant-120-35-30: #414b0b;
  --vibrant-120-50-70: #a1b445;
  --vibrant-144-15-15: #1c2919;
  --vibrant-144-3-91: #e6e5e1;
  --vibrant-144-35-30: #275023;
  --vibrant-144-50-70: #75bc6b;
  --vibrant-168-15-15: #162a21;
  --vibrant-168-3-91: #e5e5e3;
  --vibrant-168-35-30: #00513a;
  --vibrant-168-50-70: #43bf93;
  --vibrant-192-15-15: #122a28;
  --vibrant-192-3-91: #e5e5e4;
  --vibrant-192-35-30: #00504d;
  --vibrant-192-50-70: #00bfb8;
  --vibrant-216-15-15: #13292e;
  --vibrant-216-3-91: #e5e5e6;
  --vibrant-216-35-30: #004e5c;
  --vibrant-216-50-70: #0fbbd8;
  --vibrant-24-15-15: #371f1d;
  --vibrant-24-3-91: #eee3e2;
  --vibrant-24-35-30: #72342f;
  --vibrant-24-50-70: #fe8a7f;
  --vibrant-240-15-15: #182732;
  --vibrant-240-3-91: #e6e5e7;
  --vibrant-240-35-30: #004c6d;
  --vibrant-240-50-70: #4db4f1;
  --vibrant-264-15-15: #1e2635;
  --vibrant-264-3-91: #e7e5e7;
  --vibrant-264-35-30: #2a4776;
  --vibrant-264-50-70: #7cabff;
  --vibrant-288-15-15: #252435;
  --vibrant-288-3-91: #e8e4e7;
  --vibrant-288-35-30: #434176;
  --vibrant-288-50-70: #a5a1ff;
  --vibrant-312-15-15: #2b2233;
  --vibrant-312-3-91: #eae4e7;
  --vibrant-312-35-30: #563b6e;
  --vibrant-312-50-70: #c897f3;
  --vibrant-336-15-15: #31202e;
  --vibrant-336-3-91: #ebe3e6;
  --vibrant-336-35-30: #65365f;
  --vibrant-336-50-70: #e48ed6;
  --vibrant-48-15-15: #362114;
  --vibrant-48-3-91: #ede3df;
  --vibrant-48-35-30: #6f3815;
  --vibrant-48-50-70: #f89152;
  --vibrant-72-15-15: #32230f;
  --vibrant-72-3-91: #ece4de;
  --vibrant-72-35-30: #643f00;
  --vibrant-72-50-70: #e59c2e;
  --vibrant-96-15-15: #2c250e;
  --vibrant-96-3-91: #eae4de;
  --vibrant-96-35-30: #554600;
  --vibrant-96-50-70: #c8a928;
`;

export const lightTheme = css`
  --on-surface: var(--gray-10);
  --outline: var(--gray-10-20);
  --surface: var(--gray-100-50);
  --accent-0: var(--vibrant-0-35-30);
  --accent-120: var(--vibrant-120-35-30);
  --accent-144: var(--vibrant-144-35-30);
  --accent-168: var(--vibrant-168-35-30);
  --accent-192: var(--vibrant-192-35-30);
  --accent-216: var(--vibrant-216-35-30);
  --accent-24: var(--vibrant-24-35-30);
  --accent-240: var(--vibrant-240-35-30);
  --accent-264: var(--vibrant-264-35-30);
  --accent-288: var(--vibrant-288-35-30);
  --accent-312: var(--vibrant-312-35-30);
  --accent-336: var(--vibrant-336-35-30);
  --accent-48: var(--vibrant-48-35-30);
  --accent-72: var(--vibrant-72-35-30);
  --accent-96: var(--vibrant-96-35-30);
  --accent-mono: var(--gray-10);
  --bg-0: var(--vibrant-0-3-91);
  --bg-120: var(--vibrant-120-3-91);
  --bg-144: var(--vibrant-144-3-91);
  --bg-168: var(--vibrant-168-3-91);
  --bg-192: var(--vibrant-192-3-91);
  --bg-216: var(--vibrant-216-3-91);
  --bg-24: var(--vibrant-24-3-91);
  --bg-240: var(--vibrant-240-3-91);
  --bg-264: var(--vibrant-264-3-91);
  --bg-288: var(--vibrant-288-3-91);
  --bg-312: var(--vibrant-312-3-91);
  --bg-336: var(--vibrant-336-3-91);
  --bg-48: var(--vibrant-48-3-91);
  --bg-72: var(--vibrant-72-3-91);
  --bg-96: var(--vibrant-96-3-91);
  --bg-mono: var(--gray-91);
`;

export const darkTheme = css`
  --on-surface: var(--gray-100);
  --outline: var(--gray-100-10);
  --surface: var(--gray-100-10);
  --accent-0: var(--vibrant-0-50-70);
  --accent-120: var(--vibrant-120-50-70);
  --accent-144: var(--vibrant-144-50-70);
  --accent-168: var(--vibrant-168-50-70);
  --accent-192: var(--vibrant-192-50-70);
  --accent-216: var(--vibrant-216-50-70);
  --accent-24: var(--vibrant-24-50-70);
  --accent-240: var(--vibrant-240-50-70);
  --accent-264: var(--vibrant-264-50-70);
  --accent-288: var(--vibrant-288-50-70);
  --accent-312: var(--vibrant-312-50-70);
  --accent-336: var(--vibrant-336-50-70);
  --accent-48: var(--vibrant-48-50-70);
  --accent-72: var(--vibrant-72-50-70);
  --accent-96: var(--vibrant-96-50-70);
  --accent-mono: var(--gray-100);
  --bg-0: var(--vibrant-0-15-15);
  --bg-120: var(--vibrant-120-15-15);
  --bg-144: var(--vibrant-144-15-15);
  --bg-168: var(--vibrant-168-15-15);
  --bg-192: var(--vibrant-192-15-15);
  --bg-216: var(--vibrant-216-15-15);
  --bg-24: var(--vibrant-24-15-15);
  --bg-240: var(--vibrant-240-15-15);
  --bg-264: var(--vibrant-264-15-15);
  --bg-288: var(--vibrant-288-15-15);
  --bg-312: var(--vibrant-312-15-15);
  --bg-336: var(--vibrant-336-15-15);
  --bg-48: var(--vibrant-48-15-15);
  --bg-72: var(--vibrant-72-15-15);
  --bg-96: var(--vibrant-96-15-15);
  --bg-mono: var(--gray-15);
`;
