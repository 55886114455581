import { useState, useEffect } from 'react';
import { Window } from '@imus/services-ui';
import { WindowContent, Button, Content, FieldCardAction } from 'imdui';
import copy from 'copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { ChevronRightIcon, XIcon, FacebookIcon } from '@imus/base-ui';
import { css } from '@emotion/react';

const FieldCardActionStyled = styled(FieldCardAction)`
  margin-bottom: 16px;
`;
const shareActions = css`
  display: grid;
  gap: 8px;
`;

function objectToGetParams(object: {
  [key: string]: string | number | undefined | null;
}) {
  const params = Object.entries(object)
    .filter(([, value]) => value !== undefined && value !== null)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`
    );

  return params.length > 0 ? `?${params.join('&')}` : '';
}

export const ShareWindow = ({
  isOpen,
  close,
  ...props
}: {
  isOpen: boolean;
  close: () => void;
  url?: string;
}) => {
  const { t } = useTranslation();
  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setClicked(false), 1500);
    return () => {
      clearTimeout(timer);
    };
  }, [clicked]);
  const url =
    props.url || (typeof window !== 'undefined' ? window.location.href : '');
  return (
    <>
      <Window hideClose isOpen={isOpen} close={close}>
        <WindowContent
          style={{ maxWidth: '480px' }}
          onRequestClose={close}
          title={t('share')}
        >
          <FieldCardActionStyled
            primary={true}
            action={
              <Button
                appearance="fill"
                text={clicked ? t('copied') : t('copy-link')}
                primary={true}
                onClick={() => {
                  setClicked(true);
                  copy(url);
                }}
              ></Button>
            }
          >
            <Content style={{ minWidth: 0, wordBreak: 'break-all' }}>
              {url}
            </Content>
          </FieldCardActionStyled>

          <div css={shareActions}>
            <div>
              <FieldCardAction
                onClick={() => {
                  window.open(
                    `https://www.facebook.com/sharer/sharer.php${objectToGetParams({ u: url })}`
                  );
                  return;
                }}
                icon={<FacebookIcon />}
                action={<ChevronRightIcon></ChevronRightIcon>}
              >
                <Content>Facebook</Content>
              </FieldCardAction>
            </div>
            <div>
              <FieldCardAction
                onClick={() => {
                  window.open(
                    'https://twitter.com/intent/tweet' +
                      objectToGetParams({
                        url,
                      })
                  );
                  return;
                }}
                icon={<XIcon />}
                action={<ChevronRightIcon></ChevronRightIcon>}
              >
                <Content>X</Content>
              </FieldCardAction>
            </div>
          </div>
        </WindowContent>
      </Window>
    </>
  );
};
