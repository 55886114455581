/* eslint-disable react/button-has-type */
import cx from 'classnames';
import React, { forwardRef, useCallback } from 'react';
import LoadingIndicator from '../../base/LoadingIndicator';
import { Icon, SVGIcon } from '../../icon';
import { StyledButton } from './styles';

type IconButtonProps = React.ComponentProps<'button'> & {
  icon?:
    | string
    | React.FC<{ style?: React.CSSProperties; fill: string }>
    | React.VFC<React.SVGProps<SVGSVGElement>>;
  inline?: boolean;
  newIcon?: string;
  iconProps?: any;
  children?: React.ReactNode;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  type?: string;
  tabIndex?: number;
  disabled?: boolean;
  showLoading?: boolean;
  testId?: string;
};

const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    {
      testId,
      inline,
      icon: IconComponent,
      newIcon,
      iconProps,
      type = 'button',
      tabIndex = 0,
      disabled = false,
      showLoading = false,
      onClick,
      className,
      children,
      style,
    },
    ref
  ) => {
    const handleMouseDown = useCallback(
      (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
      },
      []
    );

    return (
      <StyledButton
        inline={inline}
        ref={ref}
        data-test-id={testId}
        type={type}
        tabIndex={tabIndex}
        disabled={disabled}
        onClick={!showLoading || !disabled ? onClick : undefined}
        onMouseDown={handleMouseDown}
        className={cx(className, disabled && 'disabled')}
        style={style}
      >
        {!showLoading && IconComponent && typeof IconComponent === 'string' && (
          <SVGIcon d={IconComponent} {...iconProps} />
        )}
        {!showLoading &&
          IconComponent &&
          typeof IconComponent === 'function' && (
            <IconComponent {...iconProps} />
          )}

        {!showLoading && newIcon && <Icon d={newIcon} {...iconProps} />}

        {!showLoading && children}

        {showLoading && <LoadingIndicator />}
      </StyledButton>
    );
  }
);

IconButton.displayName = 'IconButtonWithRef';

export default IconButton;
