import React, { useEffect, useState } from 'react';
import Image from 'next/image';
import { Branding, ThemeSelector, Text } from '@imus/services-ui';
import type { PageId, TemplateData } from '../types/TemplateData';
import {
  scrollWrapperStyle,
  contentSectionStyle,
  navSectionStyle,
  childrenWrapperStyle,
  avatarStyle,
  avatarSection,
  contentFooter,
  artistTitle,
  bioStyle,
  pageWrapper,
  artistPageProductLine,
} from './styles';
import { css } from '@emotion/react';
import { useTranslation, Trans } from 'next-i18next';
import { BaseUrlContext } from '../BaseUrlContext';
import { getFileUrl } from '../utils/getFileUrl';
import {
  ArtistAvatarPlaceholder,
  BodyS,
  CalendarIcon,
  Content,
  ContentS,
  ImusicianIcon,
  MusicIcon,
  NewspaperIcon,
  ShareIcon,
  UserIcon,
} from '@imus/base-ui';
import { Bio } from './Bio';
import { clickableStyle } from 'imdui/src/components/button/Clickable';
import { IconButton } from 'imdui';
import { ShareWindow } from '../ShareWindow';

type Props = {
  data: TemplateData;
  page: PageId;
  children: React.ReactNode;
  onNavigation?: (event: React.MouseEvent<HTMLElement>, page: PageId) => void;
  baseUrl?: string;
  fileBaseUrl?: string;
};

const navLinkStyle = [
  css`
    ${clickableStyle({ onClick: true, disabled: false })}
    display: flex;
    height: 56px;
    padding: 16px 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    flex: 1 0 0;

    svg path {
      stroke: var(--accent) !important;
    }
  `,
];
const activeLinkStyle = css`
  box-shadow: inset 0px -2px 0px 0px var(--accent, #1b1b1b);
`;

const shareIconStyle = css`
  --on-surface: var(--accent);
`;

const linksWrapperStyle = css`
  display: flex;
  height: 56px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  box-shadow: inset 0px -1px 0px 0px var(--outline, #1b1b1b);
`;

export const Layout: React.FC<Props> = ({
  data,
  baseUrl = '',
  fileBaseUrl = '',
  page,
  children,
  onNavigation,
}) => {
  const { t } = useTranslation();
  const [navigatedTo, setNavigatedTo] = useState<string>();
  const [shareWindowOpen, setShareWindowOpen] = useState(false);
  useEffect(() => {
    setNavigatedTo(page);
  }, [page]);
  const pageSelected = navigatedTo || page;
  const avatarSrc = data.profileImage
    ? getFileUrl(data.profileImage, fileBaseUrl)
    : undefined;

  const description = t('artist-page-description', {
    context: data.branded ? 'branded' : null,
    description: data.description,
  });
  const title = t('artist-page-title', {
    name: data.name,
    context: data.branded ? 'branded' : null,
  });

  const accent =
    data.branded && !data.preview
      ? 'var(--accent-mono)'
      : (data.settings?.colors?.accent ?? 'var(--primary-accent)');

  const background =
    data.branded && !data.preview
      ? 'var(--bg-mono)'
      : (data.settings?.colors?.background ?? 'var(--bg-mono)');

  return (
    <>
      <BaseUrlContext.Provider value={fileBaseUrl}>
        {data.branded ? (
          <ThemeSelector
            accent={'var(--accent)'}
            background={'var(--background)'}
            mode={data.settings?.colors?.theme || 'auto'}
          >
            <Branding
              callToActionUrl={t('signup-now-link')}
              callToAction={t('signup-now')}
              description={t('branding-description')}
              imusicianUrl={t('imusician-link')}
              title={t('powered-by')}
            ></Branding>
          </ThemeSelector>
        ) : null}
        <ShareWindow
          isOpen={shareWindowOpen}
          close={() => setShareWindowOpen(false)}
        />
        <ThemeSelector
          accent={accent}
          background={background}
          mode={data.settings?.colors?.theme || 'auto'}
          css={scrollWrapperStyle}
        >
          <div css={pageWrapper}>
            <div css={navSectionStyle}>
              <div css={avatarSection}>
                {avatarSrc ? (
                  <Image
                    alt="avatar"
                    src={avatarSrc}
                    css={avatarStyle}
                    width={140}
                    height={140}
                  />
                ) : (
                  <ArtistAvatarPlaceholder css={avatarStyle} />
                )}
                <div css={bioStyle}>
                  <Text.H1
                    css={artistTitle}
                    data-test-id="ReleasePageTemplate-Title"
                  >
                    {data.name}
                    <IconButton
                      inline
                      css={shareIconStyle}
                      icon={ShareIcon}
                      onClick={() => {
                        const isMobile =
                          /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                            window?.navigator.userAgent
                          );
                        if (
                          isMobile &&
                          window.navigator.canShare &&
                          window.navigator.canShare({
                            title,
                            text: description,
                            url: window.location.href,
                          })
                        ) {
                          window.navigator.share({
                            title,
                            text: description,
                            url: window.location.href,
                          });
                        } else {
                          setShareWindowOpen(true);
                        }
                      }}
                    />
                  </Text.H1>
                  <Bio text={data.description} />
                </div>
              </div>
            </div>

            <div css={linksWrapperStyle}>
              <a
                onClick={(e) => {
                  if (onNavigation) {
                    onNavigation(e, 'about');
                    setNavigatedTo('about');
                  }
                }}
                css={[
                  navLinkStyle,
                  pageSelected === 'about' && activeLinkStyle,
                ]}
                href={`${baseUrl}`}
              >
                <UserIcon />
              </a>
              {!!data.releases.length && (
                <a
                  onClick={(e) => {
                    if (onNavigation) {
                      onNavigation(e, 'releases');
                      setNavigatedTo('releases');
                    }
                  }}
                  css={[
                    navLinkStyle,
                    pageSelected === 'releases' && activeLinkStyle,
                  ]}
                  href={`${baseUrl}/releases`}
                >
                  <MusicIcon />
                </a>
              )}
              {data.tour.enabled &&
                (data.tour.events?.length || data.tour.splash) ? (
                <a
                  onClick={(e) => {
                    if (onNavigation) {
                      onNavigation(e, 'tour');
                      setNavigatedTo('tour');
                    }
                  }}
                  css={[
                    navLinkStyle,
                    pageSelected === 'tour' && activeLinkStyle,
                  ]}
                  href={`${baseUrl}/tour`}
                >
                  <CalendarIcon />
                </a>
              ) : null}
              {data.press.enabled &&
                (data.press.quotes?.length ||
                  data.press.contacts?.length ||
                  data.press.pressFiles?.length) ? (
                <a
                  onClick={(e) => {
                    if (onNavigation) {
                      onNavigation(e, 'press');
                      setNavigatedTo('press');
                    }
                  }}
                  css={[
                    navLinkStyle,
                    pageSelected === 'press' && activeLinkStyle,
                  ]}
                  href={`${baseUrl}/press`}
                >
                  <NewspaperIcon />
                </a>
              ) : null}
            </div>

            <div
              css={contentSectionStyle}
              data-test-id="ContentSection"
              data-section={pageSelected}
            >
              <div css={childrenWrapperStyle}>{children}</div>
            </div>

            <div css={contentFooter}>
              <div css={artistPageProductLine}>
                <a href={t('imusician-link') || ''}>
                  <ImusicianIcon />
                </a>
                <a href={t('artist-hub-link') || ''}>
                  <Content>{t('artist-hub')}</Content>
                </a>
              </div>
              <div css={artistPageProductLine}>
                <div style={{ display: 'flex', gap: '16px' }}>
                  <a key="1" href={t('artist-hub-imprint-link') || ''}>
                    <ContentS>Imprint</ContentS>
                  </a>
                  <a key="1" href={t('artist-hub-terms-link') || ''}>
                    <ContentS>TC</ContentS>
                  </a>
                </div>

                <div style={{ display: 'flex', gap: '8px' }}>
                  <BodyS style={{ color: 'var(--on-surface-var)' }}>
                    <Trans
                      values={{ name: data.name }}
                      i18nKey="imprint-text"
                      defaults="All Content is from the {{name}}"
                      components={[]}
                    />
                  </BodyS>
                  <Text.BodyS style={{ color: 'var(--on-surface-var)' }}>
                    ©
                  </Text.BodyS>
                  <BodyS style={{ color: 'var(--on-surface-var)' }}>
                    {new Date().getFullYear()}
                  </BodyS>
                </div>
              </div>
            </div>
          </div>
        </ThemeSelector>
      </BaseUrlContext.Provider>
    </>
  );
};
