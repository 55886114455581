import { css } from '@emotion/react';

export const brandingOverlayStyle = css`
  z-index: 3;
  position: sticky;
  bottom: 0;
  width: 100%;
  padding: 24px;
  background: linear-gradient(
    180deg,
    rgba(242, 242, 242, 0) 0%,
    rgba(242, 242, 242, 1) 100%
  );
  @media (prefers-color-scheme: dark) {
    background: linear-gradient(
      180deg,
      rgba(34, 34, 34, 0) 0%,
      rgba(34, 34, 34, 1) 100%
    );
  }

  @container (width < 576px) {
    padding: 16px 16px 0;
    & > div {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
`;

export const scrollWrapperStyle = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  font-family: 'Inter', sans-serif;
  background-color: var(--bg-4);
  overflow: auto;
  width: 100%;
  height: 100%;

  a {
    text-decoration: none;
  }

  @container template-sizer (width > 1024px) {
    scrollbar-gutter: stable;
    flex-direction: row;
    overflow: unset;
  }
`;

export const coverSectionStyle = css`
  position: relative; // for backdrop blur
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  background-size: cover;
  background-position: 50% 50% !important;
  padding: 24px;

  @container template-sizer (width > 512px) and (width < 1023px) {
    flex-direction: row;
    padding: 40px;
    align-items: center;
    justify-content: flex-start;
  }

  @container template-sizer (width > 1024px) {
    flex: 1;
    padding: 80px;
    gap: 56px;
    align-items: flex-start;
  }

  ::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(51, 51, 51, 0.4);
    backdrop-filter: blur(40px);
  }
`;

export const coverArtworkStyle = css`
  width: auto;
  aspect-ratio: 1/1;
  z-index: 1;
  height: auto;
  max-width: 80px;
  min-height: 80px;
  border-radius: 4%;
  box-shadow:
    inset 0 0 0 1px rgba(255, 255, 255, 0.1),
    0px 24px 60px -2px rgba(0, 0, 0, 0.3);

  @container template-sizer (width > 512px) {
    max-width: 176px;
  }

  @container template-sizer (width > 1024px) {
    max-width: 320px;
  }

  @container template-sizer (width > 1280px) {
    max-width: 400px;
  }
`;

export const coverContentStyle = css`
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 24px;

  @container template-sizer (width > 512px) {
    text-align: left;
  }

  @container template-sizer (width > 1024px) {
    gap: 56px;
  }
`;

export const coverTextStyle = css`
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;

  @container template-sizer (width > 1024px) {
    gap: 16px;
  }
`;

export const contentSectionStyle = css`
  // use flex: 1 for [mobile] to always keep the branding at the bottom, no
  // matter the content length
  flex: 1;
  display: flex;
  flex-direction: column;

  @container template-sizer (width > 1024px) {
    flex: none;
    width: 544px;
    overflow: auto;
    scrollbar-gutter: stable;
  }
`;

export const childrenWrapperStyle = css`
  // use flex: 1 for [mobile] and [desktop] to always keep the branding at the
  // bottom, no matter the content length
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 40px;

  @container template-sizer (width < 1024px) {
    padding-bottom: 192px;
  }
`;
