import styled from '@emotion/styled';

//
//
// Cover section
//

/** */
export const CoverTitle = styled.h1`
  font-size: 24px;
  font-weight: 700;
  line-height: 110%;
  letter-spacing: -0.02em;
  color: #fff;

  @media screen and (min-width: 1024px) {
    font-size: 40px;
  }
`;
/** */
export const CoverSubtitle = styled.span`
  font-size: 18px;
  font-weight: 700;
  line-height: 110%;
  letter-spacing: -0.01em;
  color: #fff;
  opacity: 0.8;

  @media screen and (min-width: 1024px) {
    font-size: 24px;
  }
`;

//
//
// Content section
//

export * from '@imus/base-ui/src/text';
