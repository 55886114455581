import { Window, Text, Card } from '@imus/services-ui';
import styled from '@emotion/styled';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

const ShowMoreButton = styled.button`
  margin-top: 8px;
  display: inline-flex;
  border: none;
  cursor: pointer;
  background: transparent;

  ${Text.ContentM} {
    color: var(--accent, #1b1b1b);
  }
`;

const BioText = styled(Text.BodyM)`
  color: var(--fg-2);
  position: relative;
  white-space: pre-line;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
`;
const FullBioText = styled(Text.BodyM)`
  white-space: pre-line;
`;
export const Bio = ({ text }: { text: string }) => {
  const [displayShowMore, setDisplayShowMore] = useState(false);
  const { t } = useTranslation();

  const [displayBioModal, setDisplayBioModal] = useState(false);
  const bioTextRef = useRef<HTMLSpanElement | null>(null);
  useEffect(() => {
    if (!bioTextRef.current) return;
    if (bioTextRef.current?.scrollHeight > bioTextRef.current?.clientHeight) {
      setDisplayShowMore(true);
    }
  }, [text]);
  return (
    <>
      <Window
        isOpen={displayBioModal}
        close={() => {
          setDisplayBioModal(false);
        }}
        title={t('about')}
      >
        <Card style={{ padding: '32px' }}>
          <Text.H2 style={{ marginBottom: '32px' }}>{t('about')}</Text.H2>
          <FullBioText data-test-id="FullBioText">{text}</FullBioText>
        </Card>
      </Window>
      <div style={{ position: 'relative' }}>
        <BioText data-test-id="BioText" ref={bioTextRef}>
          {text}
        </BioText>
        {displayShowMore && (
          <ShowMoreButton
            data-test-id="ShowMoreButton"
            onClick={() => {
              setDisplayBioModal(true);
            }}
          >
            <Text.ContentM>{t('show-more')}</Text.ContentM>
          </ShowMoreButton>
        )}
      </div>
    </>
  );
};
