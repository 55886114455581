import styled from '@emotion/styled';
import { motion } from 'framer-motion';

export const cardBoxShadow = '0px 3px 5px rgba(0, 0, 0, 0.06)';

const Card = styled.div`
  position: relative;
  border-radius: 12px;
  box-shadow: ${cardBoxShadow};
  overflow: hidden;
`;

export const AnimatedDim = styled(motion.div)`
  z-index: 103;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
`;

export const WindowCard = styled(Card.withComponent(motion.div))`
  width: 100%;
  max-width: 576px;
  max-height: 90%;
  margin: 24px;
  cursor: default;
  overflow: auto;
  margin-bottom: 32px;
`;

export const WindowChildren = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
