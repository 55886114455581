import { accentThemes, BodyS, Content, ImusicianIcon } from '@imus/base-ui';
import { LinkButton } from 'imdui';
import { css } from '@emotion/react';

const brandingStyle = css`
  ${accentThemes.primary}
  display: flex;
  width: 100%;
  min-height: 88px;
  padding: 24px;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 10px var(--space-S, 16px);
  flex-shrink: 0;
  flex-wrap: wrap;
  border-bottom: 1px solid var(--accent, #004d63);
  background: var(--accent-surface-container, #fafcff);
  svg path {
    fill: var(--on-surface, #004d63) !important;
  }
`;
const containerStyle = css`
  display: flex;
  flex: 1;
  max-width: 600px;
  align-items: center;
  gap: 16px;
  gap: var(--space-S, 16px);
  flex: 1 0 0;

  @container (width < 512px) {
    flex-direction: column;
  }
`;
const textStyle = css`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 4px;
  flex: 1 0 0;
  margin-right: var(--space-S, 16px);
  padding-right: var(--space-S, 16px);
  border-right: 1px solid var(--outline-var, #004d63);
  @container (width < 512px) {
    border-right: none;
    margin-right: 0;
    padding-right: 0;
  }
`;
const actionContainerStyle = css`
  display: flex;
  margin-left: auto;
  align-self: center;
  text-align: right;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 16px;
`;
const containerButtonStyle = css`
  display: flex;
  @container (width < 512px) {
    flex: 1;
    width: 100%;
    flex-direction: column;
  }
  align-self: center;
`;

export const Branding = ({
  imusicianUrl,
  description,
  callToAction,
  callToActionUrl,
  title,
}: {
  imusicianUrl: string;
  description: string;
  callToAction: string;
  callToActionUrl: string;
  title: string;
}) => {
  return (
    <div css={brandingStyle}>
      <div css={containerStyle}>
        <div css={actionContainerStyle}>
          <a href={imusicianUrl} target="_blank" rel="noopener noreferrer">
            <ImusicianIcon />
          </a>
          <div css={textStyle}>
            <Content>{title}</Content>
            <BodyS secondary>{description}</BodyS>
          </div>
        </div>

        <div css={containerButtonStyle}>
          <LinkButton
            appearance="stroke"
            primary
            data-component="imusician-signup-link"
            external
            to={callToActionUrl || '/'}
            text={callToAction}
          ></LinkButton>
        </div>
      </div>
    </div>
  );
};
